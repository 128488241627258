
  export const style={
    inpuStyle:{
      colorInput:"#272727"
    },
    url:{
      urlClient:"https://arizona.prd.appnoz.com.br/",
      urlClientYouseed:"https://admyouseed.bancosemear.com.br/"
    },
    fileUrl:{
      logo:"https://timcoo-geral.s3.amazonaws.com/Logos/Login+Web.png"
    },
    CurrencyName:{
      name:"MoedAZ/MoedAZ"
    },
    teamName:{
      name:"Grupo Arizona"
    },
    hasToken: false,
    hostHeader: "arizona"
  }
